import React from "react";
import { howReikiWorks } from "../../data/how-reiki-works";
import TitleCard from "../shared/TitleCard";

const HowReikiWorks = () => {
    return (
        <div className="px-4 px-md-5">
            <h2 className="text-center fw-bold display-5 mb-5 pb-5">{howReikiWorks.heading}</h2>
            <div className="row px-md-5">
                {howReikiWorks.content.map((card, index) => (
                    <div key={index} className="col-12 col-md-4 d-flex align-items-stretch mb-4">
                        <TitleCard title={card.title} description={card.description} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HowReikiWorks;