import { SessionProps } from "../interfaces/session.interface";

export const sessions: SessionProps[] = [
    {
        time: 30,
        heading: "Chakra Balancing",
        price: 260,
        colour: "#A9D9FD",
        benefits: [
            { benefit: "Balance and align your chakras", included: true },
            { benefit: "Restore energy flow and harmony", included: true },
            { benefit: "Ideal for a quick reset and energy boost", included: true },
            { benefit: "Focus on physical and emotional issues", included: false },
            { benefit: "Includes trauma healing", included: false },
        ],
    },
    {
        time: 45,
        heading: "Reiki Healing",
        price: 350,
        colour: "#AABCFE",
        benefits: [
            { benefit: "Balance and align your chakras", included: true },
            { benefit: "Restore energy flow and harmony", included: true },
            { benefit: "Provide targeted Reiki healing for more specific areas of concern", included: true },
            { benefit: "Focus on physical and emotional issues", included: true },
            { benefit: "Includes trauma healing", included: false },
        ],
        popularVariant: true,
    },
    {
        time: 60,
        heading: "Extensive Reiki",
        price: 450,
        colour: "#FCDAFF",
        benefits: [
            { benefit: "Balance and align your chakras", included: true },
            { benefit: "Restore energy flow and harmony", included: true },
            { benefit: "Provide targeted Reiki healing for more specific areas of concern", included: true },
            { benefit: "Focus on physical and emotional issues", included: true },
            { benefit: "Includes trauma healing", included: true },
        ],
    },
];