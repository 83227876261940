import React from "react";
import Testimonial from "../shared/Testimonial";
import { testimonials } from "../../data/testimonials";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

const ClientStories = () => {
	let settings = {
		className: "center",
		centerMode: true,
		centerPadding: "0",
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 1,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    pauseOnHover: true
				},
			},
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    pauseOnHover: true
                },
            }
		],
	};
	return (
		<div className="px-3 px-md-5">
			<h2 className="text-center fw-bold display-5">Client Stories</h2>
			<Slider {...settings}>
				{testimonials.map((testimonial, index) => (
					<Testimonial
						key={index}
						testimonial={testimonial.testimonial}
						person={testimonial.person}
						jobTitle={testimonial.jobTitle}
					/>
				))}
			</Slider>
		</div>
	);
};

export default ClientStories;
