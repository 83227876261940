import React, { FC } from "react";
import { PageHeadingProps } from "../../interfaces/page-heading.interface";

const PageHeading: FC<PageHeadingProps> = ({
    heading
}) => {
    return (
        <div className="page-heading">
            <img src="images/gradient.webp" alt="" className="bg-gradient"  />
            <div className="overlay">
                <h1 className="fancy-text mt-5">{heading.toUpperCase()}</h1>
            </div>
        </div>
    );
};

export default PageHeading;