import React, { useState, useEffect, useCallback } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useLocation } from "react-router-dom";

const TopNavbar = () => {
	const location = useLocation();
	const [scrolled, setScrolled] = useState(false);
	const [activeKey, setActiveKey] = useState(location.pathname);

	const handleScroll = useCallback(() => {
		const threshold = location.pathname === "/" ? document.documentElement.clientHeight : window.innerHeight * 0.24;
		const show = window.scrollY > threshold;
		if (show !== scrolled) setScrolled(show);
	}, [location.pathname, scrolled]);

	useEffect(() => {
        setActiveKey(location.pathname + location.hash);
    }, [location]);

	useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
	}, [handleScroll]);

	useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
	}, );

	return (
		<Navbar fixed="top" expand="lg" className={`px-3 pe-md-5 ps-md-4 glassmorphism ${scrolled ? 'bg-white' : 'bg-transparent'}`}>
			<Navbar.Brand href="/" className={!scrolled && activeKey === "/" ? 'invisible' : ''}>
				<div className="d-flex align-items-center">
					<img src="images/logo-dark.svg" alt="" className="navbar-logo" />
					<p className="fancy-text mb-0 ms-2 navbar-title">Luna Pearl</p>
				</div>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="ms-auto fw-medium" activeKey={activeKey}>
					<Nav.Link className="text-center text-md-start mt-4 mt-md-0 py-4 py-md-0" href="/" active={activeKey === "/"}>Home</Nav.Link>
					<Nav.Link className="text-center text-md-start py-4 py-md-0" href="/#about-luna-pearl" active={activeKey === "/#about-luna-pearl"}>About Us</Nav.Link>
					<Nav.Link className="text-center text-md-start py-4 py-md-0" href="/#sessions" active={activeKey === "/#sessions"}>Sessions</Nav.Link>
					<Nav.Link className="text-center text-md-start py-4 py-md-0" href="/what-is-reiki" active={activeKey === "/what-is-reiki"}>What is Reiki?</Nav.Link>
					<Nav.Link className="text-center text-md-start py-4 py-md-0" href="/contact-us" active={activeKey === "/contact-us"}>Contact Us</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default TopNavbar;
