import React from "react";
import Sessions from "../sections/Sessions";
import ClientStories from "../sections/ClientStories";
import MeetYourSpecialist from "../sections/MeetYourSpecialist";
import Footer from "../shared/Footer";
import Promotions from "../sections/Promotions";
import Hero from "../sections/Hero";
import About from "../sections/About";
import Spacer from "../shared/Spacer";
import { Link } from "react-router-dom";

const Home = () => {
	return (
		<div>
            <Hero />
            <Spacer />
            <div className="my-5" id="about-luna-pearl">
                <About />
            </div>
            <div id="sessions">
                <Spacer />
                <Sessions />
            </div>
            <div id="promotions">
                <Spacer />
                <Promotions />
            </div>
            <div id="client-stories">
                <Spacer />
                <ClientStories />
            </div>
            <div id="meet-your-specialist">
                <Spacer />
                <MeetYourSpecialist />
            </div>
            <Spacer />
            <div className="d-flex justify-content-center">
                <Link to="/contact-us" className="btn btn-dark btn-lg p-3">Contact Us</Link>
            </div>
            <Spacer />
            <Footer />
		</div>
	);
};

export default Home;
