import React, { FC } from "react";
import { TestimonialProps } from "../../interfaces/testimonial.interface";

const Testimonial: FC<TestimonialProps> = ({
	testimonial,
	person,
	jobTitle,
}) => {
	return (
		<div className="d-flex align-items-stretch px-2">
			<div className="bg-secondary rounded rounded-5 my-4 my-md-5 p-4 p-xxxl-5 d-flex flex-column h-100">
				<img className="mb-4 quotes-img" src="images/quote.svg" alt="" />
				<p className="fs-xxl-5">{testimonial}</p>
				<div className="mt-auto">
					<small className="fw-semibold mb-0 d-block">{person}</small>
					<small>{jobTitle}</small>
				</div>
			</div>
		</div>
	);
};

export default Testimonial;