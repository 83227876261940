import React from "react";
import PageHeading from "../shared/PageHeading";
import Footer from "../shared/Footer";
import ContactForm from "../sections/ContactForm";
import Spacer from "../shared/Spacer";
import { contact } from "../../data/contact";
import { Button } from "react-bootstrap";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebookMessenger } from "react-icons/fa";

const ContactUs = () => {
	return (
		<div>
			<PageHeading heading="Contact Us" />
			<div className="text-center">
				<div className="d-flex justify-content-center w-100 mt-5">
					<a
						className="me-3"
						href={`https://wa.me/${contact.phone.replace(/\s/g, "")}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Button
							size="lg"
							variant="success"
							className="small-button-font text-white"
						>
							<IoLogoWhatsapp className="me-2" />
							<span>WhatsApp Us</span>
						</Button>
					</a>
					<a
						href="http://m.me/LunaPearlZA"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Button
							size="lg"
							variant="info"
							className="small-button-font text-white"
						>
							<FaFacebookMessenger className="me-2" />
							<span>Message Us</span>
						</Button>
					</a>
				</div>
				<p className="mt-4">- OR -</p>
			</div>
			<ContactForm />
			<Spacer />
			<Footer />
		</div>
	);
};

export default ContactUs;
