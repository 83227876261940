import React, { FC } from "react";
import { PromotionProps } from "../../interfaces/promotion.interface";

const Promotion: FC<PromotionProps> = ({
    imageSrc,
    heading,
    description
}) => {
    return (
        <div className="my-4 my-md-0">
            <div className="d-flex align-items-center">
                <img src={imageSrc} alt="Reiki" className="img-fluid me-3 promotion-image" />
                <h4 className="fw-semibold">{heading}</h4>
            </div>
            <p className="pe-2 pe-md-4 mt-4 smaller-font">{description}</p>
        </div>
    );
};

export default Promotion;