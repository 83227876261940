import React from "react";
import ImageSide from "../shared/ImageSide";

const DistanceReiki = () => {
	return (
		<ImageSide left={false} imageSrc="images/distance-reiki.svg">
			<h2 className="fw-bold display-5 mb-5">Distance Reiki</h2>
			<p>
				Energy healing from anywhere. Practitioners transmit Reiki energy
				remotely, promoting relaxation, balance, and healing through focused
				intention and meditation. Just as effective as in-person sessions.
			</p>
		</ImageSide>
	);
};

export default DistanceReiki;
