import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./components/pages/Home";
import WhatIsReiki from "./components/pages/WhatIsReiki";
import TopNavbar from "./components/shared/TopNavbar";
import ContactUs from "./components/pages/ContactUs";

function App() {
	return (
		<Router>
			<TopNavbar />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/what-is-reiki" element={<WhatIsReiki />} />
        <Route path="/contact-us" element={<ContactUs />} />
			</Routes>
		</Router>
	);
}

export default App;
