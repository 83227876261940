import React from "react";
import { FaPhone, FaSquareFacebook } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { contact } from "../../data/contact";

const Footer = () => {
	return (
		<footer className="bg-dark text-white px-4 py-5 d-flex flex-column align-items-center">
			<div className="row w-100">
				<div className="col-md-4">
					<p className="fw-bold">Contact Us</p>
					<a
						href={`tel:${contact.phone.replace(/\s/g, "")}`}
						className="footer-link text-white text-decoration-none d-block mb-1"
					>
						<FaPhone className="me-2" />
						<span>{contact.phone}</span>
					</a>
					<a
						href={`mailto:${contact.email}`}
						className="footer-link text-white text-decoration-none d-block"
					>
						<IoMdMail className="me-2" />
						<span>{contact.email}</span>
					</a>
				</div>
				<div className="col-md-4 mt-5 mt-md-0 text-center">
					<img className="footer-logo" src="images/logo-white.svg" alt="" />
				</div>
				<div className="col-md-4 mt-5 mt-md-0 text-end">
					<p className="fw-bold">Socials</p>
					<a
						href="https://www.facebook.com/profile.php?id=61561020513368"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaSquareFacebook className="text-white fs-2" />
					</a>
				</div>
			</div>
			<small className="d-block mt-4">&copy; 2024 Luna Pearl</small>
		</footer>
	);
};

export default Footer;
