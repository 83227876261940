import React from "react";
import { reikiBenefits } from "../../data/reiki-benefits";
import Chip from "../shared/Chip";

const ReikiBenefits = () => {
    return (
        <div className="px-1 px-md-5">
            <h2 className="text-center fw-bold display-5 mb-3 mb-md-5 pb-5">{reikiBenefits.heading}</h2>
            <div className="d-flex justify-content-center flex-wrap px-md-5">
                {reikiBenefits.content.map((text, index) => (
                    <div key={index} className="m-1">
                        <Chip text={text} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ReikiBenefits;