import React from "react";
import ImageSide from "../shared/ImageSide";

const MeetYourSpecialist = () => {
	return (
		<div>
			<ImageSide imageSrc="images/portrait.webp">
				<h2 className="fw-bold display-5">Meet Your Specialist</h2>
				<h4 className="mb-5">Colleen Matthews</h4>
				<p>
					As a certified Reiki practitioner trained in Usui Reiki levels 1, 2,
					and Master, as well as Egyptian Reiki of the Light, I bring both
					passion and experience to my practice. My journey in holistic healing
					began with a deep interest in wellness, which led me to work in
					reflexology, sports massage therapy, and fitness instruction. These
					diverse experiences have enriched my understanding of the body and its
					incredible capacity for healing.
					<br/><br/>
					In addition to Reiki, I offer
					comprehensive lifestyle guidance to support your overall well-being,
					ensuring that all aspects of your health—physical, emotional, and
					spiritual—are nurtured and aligned.
				</p>
			</ImageSide>
		</div>
	);
};

export default MeetYourSpecialist;
