export const howReikiWorks = {
    heading: "How Reiki Works",
    content: [
        {
            title: 'Channeling Energy',
            description: 'The Reiki practitioner channels universal life force energy through their hands into the client\'s body.'
        },
        {
            title: 'Balancing Energy',
            description: 'Reiki restores balance to the body\'s energy fields, promoting relaxation, reducing stress, and enhancing well-being.'
        },
        {
            title: 'Activating Healing',
            description: 'Reiki stimulates the body\'s natural healing processes, supporting physical, emotional, and spiritual health.'
        },
        {
            title: 'Dissolving Blockages',
            description: 'Reiki releases energetic blockages, allowing free energy flow and promoting healing and balance.'
        },
        {
            title: 'Enhancing Intuition',
            description: 'Reiki increases intuition, helping individuals connect with their inner selves and access inner wisdom.'
        },
        {
            title: 'Supporting Spiritual Growth',
            description: 'Reiki deepens spiritual practices, fostering a connection to the universe and promoting personal growth.'
        }
    ],
}