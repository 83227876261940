import React, { FC } from "react";
import { Container } from "react-bootstrap";

interface InputProps {
    imageSrc: string;
    left?: boolean;
    children: React.ReactNode;
}

const ImageSide: FC<InputProps> = ({imageSrc, left = true, children}) => {
	return (
        <Container className="d-flex align-items-center">
            <div className="row px-3 px-md-0">
                <div className={`col-md-7 offset-md-1 ${left ? "order-md-1" : ""}`}>
                    {children}
                </div>
                <div className={`col-md-4 mt-5 mt-md-3 ${left ? "order-md-0" : ""}`}>
                    <img src={imageSrc} alt="Reiki" className="img-fluid rounded rounded-5" />
                </div>
            </div>
        </Container>
    )
};

export default ImageSide;
