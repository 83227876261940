import React, { FC } from "react";
import { ChipProps } from "../../interfaces/chip.interface";

const Chip: FC<ChipProps> = ({
    text
}) => {
    return (
        <div className="p-2 p-md-3 rounded rounded-3 rounded-md-4 bg-primary grow-on-hover cursor-default">
            <p className="text-white fw-semibold m-0 p-0 chip-font">{text}</p>
        </div>
    );
};

export default Chip;