import React from "react";
import Promotion from "../shared/Promotion";
import { promotions } from "../../data/promotions";
import { Container } from "react-bootstrap";

const Promotions = () => {
	return (
		<div className="py-5 mt-5">
			<Container className="my-5">
				<div className="row px-3 px-md-0">
					{promotions.map((promotion, index) => (
						<div className="col-md-4" key={index}>
							<Promotion {...promotion} />
						</div>
					))}
				</div>
			</Container>
		</div>
	);
};

export default Promotions;
