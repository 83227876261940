import { PromotionProps } from "../interfaces/promotion.interface";
const path = "images";
const fileExtension = ".svg";

export const promotions: PromotionProps[] = [
    {
        imageSrc: `${path}/free-session${fileExtension}`,
        heading: "Enjoy a free session",
        description: "Feeling unsure? Rest assured, your first 30-minute session is free of charge!"
    },
    {
        imageSrc: `${path}/gift-voucher${fileExtension}`,
        heading: "Share with a gift voucher",
        description: "Treat someone special to a journey of balance, peace, and rejuvenation with a Luna Pearl gift voucher."
    },
    {
        imageSrc: `${path}/reiki-for-pets${fileExtension}`,
        heading: "Reiki helps your pet too",
        description: "Reiki helps alleviate stress, aids in healing, and assists with behavioural issues for your furry companions."
    }
];