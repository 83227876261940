import React from "react";
import SessionCard from "../shared/SessionCard";
import { sessions } from "../../data/sessions";
import { Container } from "react-bootstrap";

const Sessions = () => {
    return (
        <Container fluid="xxl" className="vh-80 d-flex flex-column justify-content-center px-4 px-xxl-0">
            <h2 className="text-center fw-bold display-5 mb-5">Sessions</h2>
            <div className="row d-flex align-items-center">
                {sessions.map((session, index) => (
                    <div className="col-md-4" key={index}>
                        <SessionCard
                            time={session.time}
                            heading={session.heading}
                            price={session.price}
                            colour={session.colour}
                            benefits={session.benefits}
                            popularVariant={session.popularVariant}
                        />
                    </div>
                ))}
            </div>
        </Container>
    );
};

export default Sessions;