import React, { FC } from "react";
import { TitleCardProps } from "../../interfaces/title-card.interface";

const TitleCard: FC<TitleCardProps> = ({
    title,
    description
}) => {
    return (
        <div className="p-4 rounded rounded-4 bg-secondary grow-on-hover cursor-default">
            <p className="text-primary fw-bold">{title}</p>
            <p>{description}</p>
        </div>
    );
};

export default TitleCard;