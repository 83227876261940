import React from "react";
import ImageSide from "../shared/ImageSide";
import { understandingChakras } from "../../data/understanding-chakras";

const UnderstandingChakras = () => {
	return (
		<ImageSide left={false} imageSrc="images/understanding-chakras.svg">
			<h2 className="fw-bold display-5 mb-5">{understandingChakras.heading}</h2>
			<ul>
				{understandingChakras.content.map((c, index) => (
					<li key={index} className="fs-5 mb-2">{c}</li>
				))}
			</ul>
		</ImageSide>
	);
};

export default UnderstandingChakras;
