import React, { useEffect, useRef, useState } from "react";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { Utils } from "../../Utils";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import FormikInput from "../shared/FormikInput";

const Schema = Yup.object({
	subject: Yup.string()
		.min(3, "Subject must be at least 3 characters")
		.max(20, "Subject must be less than 20 characters")
		.required("Please enter a subject"),
	email: Yup.string()
		.email("Please enter a valid email")
		.required("Please enter an email"),
	message: Yup.string().required("Please enter a message"),
});

const ContactForm = () => {
	const [showToast, setShowToast] = useState(false);
	const [dangerToast, setDangerToast] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		setIsMobile(window.innerWidth < 800);
	}, []);

	const form = useRef(null);

	const sendEmail = () => {
		if (!form.current) {
			return Promise.reject(new Error("Form not found"));
		}
	
		return emailjs
			.sendForm("service_95i26no", "template_98be2nf", form.current, {
				publicKey: "EMotZoMbsTqV390uC",
			})
			.then(
				(result) => {
					setShowToast(true);
					return result;
				},
				(error) => {
					console.error("Failed to send email", error);
					setDangerToast(true);
				}
			);
	};

	return (
		<div>
			<ToastContainer className="p-3" position="bottom-end">
				<Toast
					onClose={() => {
						setShowToast(false);
						setDangerToast(false);
					}}
					show={showToast || dangerToast}
					delay={2000}
					autohide
					bg={showToast ? "success" : "danger"}
				>
					<Toast.Header
						className={`border-0 text-white ${
							showToast ? "bg-success" : "bg-danger"
						}`}
						closeButton={false}
					>
						<strong className="me-auto">{showToast ? "Sent" : "Error"}</strong>
						<small>{Utils.getCurrentDate()}</small>
					</Toast.Header>
					<Toast.Body className="text-white">
						{showToast
							? "Your message has been sent!"
							: "There was an error sending your message"}
					</Toast.Body>
				</Toast>
			</ToastContainer>

			<div className="d-flex justify-content-center p-3 mt-4">
				<Formik
					initialValues={{ subject: "", email: "", message: "" }}
					validationSchema={Schema}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						setSubmitting(true);
						sendEmail().then(() => {
							resetForm();
							setSubmitting(false);
						});
					}}
				>
					{({ errors, touched, isValid, isSubmitting, dirty }) => (
						<Form className={isMobile ? "w-100" : "w-50"} ref={form}>
							<FormikInput type="Email" errors={errors} touched={touched} />
							<FormikInput type="Subject" errors={errors} touched={touched} />
							<FormikInput type="Message" errors={errors} touched={touched} />

							<div className="w-100 text-end mt-5">
								<Button
									className="text-white fw-medium"
									variant="primary"
									type="submit"
									size="lg"
									disabled={!isValid || isSubmitting || !dirty}
								>
									{isSubmitting ? "Sending..." : "Send"}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default ContactForm;
