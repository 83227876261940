import { TestimonialProps } from "../interfaces/testimonial.interface";

export const testimonials: TestimonialProps[] = [
	{
		testimonial:
			"Being an MS patient, I face difficulties which Col embraced with positivity. She provides relief to my body or specific areas I’m struggling with. She identifies these spots accurately during our sessions. In addition to Reiki, Col ensures my body functions optimally and my mind stays positive. Thank you Col for the love and light.",
		person: "Bronwen",
		jobTitle: "Teacher",
	},
	{
		testimonial: "Her being a friend and fitness instructor, I've known Coll for years. Despite my skepticism about distance Reiki, I tried it for my back pain, sore shins, and piriformis syndrome. After starting sessions with Colleen, my pain vanished, and she's also supporting me through emotional challenges. I can attest to Colleen's healing abilities.",
		person: "Penny",
		jobTitle: "Teacher",
	},
	{
		testimonial:
			"Reiki with Colleen has been a lifesaver during perimenopause! Her expertise and soothing presence have brought me calm and balance, reducing anxiety and mood swings. I feel more centred and in control of my emotions. Plus, her treatments helped me recover faster from bronchitis. Colleen's Reiki has made a huge difference in my overall well-being.",
		person: "Jill",
		jobTitle: "Teacher",
	},
	{
		testimonial:
			"As someone who struggles with stress and anxiety, especially during exams, finding Reiki with Colleen has been amazing. Before Reiki, my anxiety made it hard to focus and remember things. Since starting sessions, my stress levels have dropped significantly, and I feel more centred and peaceful, allowing me to study with a clear, calm mind.",
		person: "Shaelyn",
		jobTitle: "University Student",
	},
	{
		testimonial:
			"Colleen has also done Reiki work on my 14-year-old Pekingese dog, who is going blind in one eye. His eye used to get a horrible yellow mucus, making him uncomfortable. After his Reiki sessions with Colleen, his eye has been clear for days — something I never thought possible. I can't thank her enough for her dedication and compassion.",
		person: "Jill - Pet work",
		jobTitle: "Teacher",
	},
	{
		testimonial:
			"I experience a hormone imbalance, and Reiki sessions with Colleen have greatly improved my condition. My skin has cleared up significantly, and I no longer have stomach cramps. When I was sick and very congested, a Reiki session with Colleen helped me feel much better. She also recommended crystal therapy and affirmations for my stutter, which has shown remarkable improvement.",
		person: "Nicole",
		jobTitle: "Bartender",
	},
	{
		testimonial: "As a skeptic, I found it difficult at first to trust the work that Colleen does. However, after seeing the impression she has when dealing with animals and their well-being, I have come to trust her work. She also has a vast amount of knowledge on the health and wellness of an individual, outside of the Reiki work she does. I would recommend her to anyone.",
		person: "Keelan",
		jobTitle: "Software Engineer",
	}
];
