import React, { FC } from "react";
import { SessionProps } from "../../interfaces/session.interface";
import { FaCheck, FaTimes } from "react-icons/fa";

const SessionCard: FC<SessionProps> = ({
	time,
	heading,
	price,
	colour,
	benefits,
	popularVariant = false,
}) => {
	const handleBookingClick = () => {
		// Use window.open to navigate to the URL
		window.open(
			"https://calendar.google.com/calendar/appointments/schedules/AcZssZ3Sqcc3WypNAiD7eKGFQMPzQ9O4oxk7PeJQ165k3NKi5E8sGqOAmjVTzFMutkfpsJX9Juxuby94?gv=true",
			"_blank"
		);
	};

	return (
		<div className="position-relative">
			{popularVariant && (
				<>
					<img
						src="images/gradient.webp"
						alt=""
						className="bg-gradient rounded rounded-5"
					/>
					<div className="position-absolute top-0 start-0 ms-4 negative-top">
						<span className="badge bg-primary text-white p-2">Popular</span>
					</div>
				</>
			)}
			<div
				className={`rounded rounded-5 mb-4 ${
					popularVariant ? "py-md-4" : "bg-secondary"
				}`}
			>
				<div className="p-4 p-xxl-5">
					<div className="d-flex align-items-center mb-4">
						<div
							className="p-4 rounded-circle"
							style={{ backgroundColor: colour }}
						></div>
						<div className="ms-3">
							<small>{time} minutes</small>
							<h5 className="fw-medium">{heading}</h5>
						</div>
					</div>

					<h6 className="fs-2 mb-2 fw-semibold mb-4">R{price}</h6>
					<ul className="list-unstyled">
						{benefits.map((benefit, index) => (
							<li key={index} className="mb-2 d-flex card-typography">
								<span className="d-block">
									{benefit.included ? (
										<FaCheck className="text-primary me-2" />
									) : (
										<FaTimes className="text-muted me-2" />
									)}
								</span>
								<span className={benefit.included ? "" : "text-muted"}>
									{benefit.benefit}
								</span>
							</li>
						))}
					</ul>
					<button
						onClick={handleBookingClick}
						className={`w-100 btn ${
							popularVariant ? "btn-light" : "btn-primary"
						} mt-3`}
					>
						<p
							className={`${
								popularVariant ? "text-primary" : "text-white"
							} my-1`}
						>
							Book Session
						</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default SessionCard;
