import React from "react";
import ImageSide from "../shared/ImageSide";

const About = () => {
	return (
		<ImageSide imageSrc="images/logo-chakras.svg">
			<h2 className="fw-bold display-5 mb-5">About Luna Pearl</h2>
			<p>
				At Luna Pearl, we are dedicated to guiding you on a journey of holistic
				healing, spiritual enlightenment, and lifestyle transformation. We
				harness the gentle, transformative power of Reiki to restore balance,
				promote well-being, and awaken your inner peace. Whether you seek relief
				from physical ailments, emotional harmony, or spiritual growth, Luna
				Pearl is here to support and empower you every step of the way. Embrace
				a life of tranquility, clarity, and abundant health with our
				personalized healing and guidance services.
			</p>
		</ImageSide>
	);
};

export default About;
