import React from "react";
import ImageSide from "../shared/ImageSide";

const EnergyFlow = () => {
	return (
		<ImageSide imageSrc="images/energy-flow.svg">
			<h2 className="fw-bold display-5 mb-5">Understanding Energy Flow</h2>
			<ul>
				<li className="fs-5 mb-2">
					Everything in the universe, including ourselves, operates on vibrational
					frequencies. These frequencies can be influenced by external factors like
					people, media, and environment, as well as internal factors like thoughts
					and emotions.
				</li>
				<li className="fs-5 mb-2">
					Energy flows through the body like water in a river; blockages from these
					external and internal factors disrupt this flow, affecting physical, mental,
					emotional, and psychological health.
				</li>
				<li className="fs-5 mb-2">
					Understanding and harmonizing these frequencies is key to maintaining
					overall well-being.
				</li>
			</ul>
		</ImageSide>
	);
};

export default EnergyFlow;
