import React from "react";
import "../../styles/hero.css";
import { Link } from "react-router-dom";

const Hero = () => {
    return (
        <div className="hero">
            <img src="images/gradient.webp" alt="" className="bg-gradient"  />
            <div className="overlay">
                <img src="images/logo-white.svg" alt="Luna Pearl Logo" className="hero-logo" />
                <h1 className="fancy-text hero-heading">LUNA PEARL</h1>
                <div className="hero-buttons">
                    <Link to="/contact-us" className="btn btn-light">
                        <span className="fw-semibold">CONTACT US</span>
                    </Link>
                    <Link to="what-is-reiki" className="btn btn-dark">
                        <span className="fw-medium">WHAT IS REIKI?</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Hero;