import React, { FC } from "react";
import { FormikInputProps } from "../../interfaces/formik-input.interface";
import { ErrorMessage, Field } from "formik";
import { FloatingLabel, FormControl, FormGroup } from "react-bootstrap";

interface Settings {
	type?: string;
	placeholder: string;
	as?: string;
	rows?: string;
}

const FormikInput: FC<FormikInputProps> = ({ type, errors, touched }) => {
	const name = type.toLowerCase();
	const label = `${type} ${type === "Email" ? " address" : ""}`;
	let settings: Settings = { placeholder: "" };

	switch (type) {
		case "Email":
			settings = {
				type: "email",
				placeholder: "Email",
			};
			break;
		case "Subject":
			settings = {
				type: "text",
				placeholder: "Subject",
			};
			break;
		case "Message":
			settings = {
				as: "textarea",
				rows: "6",
				placeholder: "Message",
			};
			break;
		default:
			break;
	}

	return (
		<Field name={name}>
			{({ field }: {field: any}) => (
				<FormGroup className="mb-3" controlId={`formBasic${type}`}>
					<FloatingLabel controlId="floatingInput" label={label}>
						<FormControl
							{...settings}
							{...field}
							className={touched[name] && errors[name] ? "is-invalid" : "mb-4"}
						/>
						<ErrorMessage
							component="div"
							name={name}
							className="invalid-feedback"
						/>
					</FloatingLabel>
				</FormGroup>
			)}
		</Field>
	);
};

export default FormikInput;
