import React from "react";
import PageHeading from "../shared/PageHeading";
import Spacer from "../shared/Spacer";
import UnderstandingChakras from "../sections/UnderstandingChakras";
import HowReikiWorks from "../sections/HowReikiWorks";
import ReikiBenefits from "../sections/ReikiBenefits";
import EnergyFlow from "../sections/EnergyFlow";
import DistanceReiki from "../sections/DistanceReiki";
import Footer from "../shared/Footer";

const WhatIsReiki = () => {
	return (
		<div>
			<PageHeading heading="What is Reiki?" />
			<Spacer />
			<div className="my-5">
				<UnderstandingChakras />
			</div>
			<Spacer />
			<HowReikiWorks />
			<Spacer />
			<ReikiBenefits />
			<Spacer />
			<Spacer />
			<EnergyFlow />
			<Spacer />
			<Spacer />
			<DistanceReiki />
			<Spacer />
			<Footer />
		</div>
	);
};

export default WhatIsReiki;
