export const reikiBenefits = {
    heading: "Benefits of Reiki",
    content: [
        'Increased vitality',
        'Stimulates the immune system',
        'Emotional healing',
        'Hormone balancing',
        'Aids in recovery after illness',
        'Stress relief',
        'Enhances sleep quality',
        'Pain relief',
        'Assists body in cleansing toxins',
        'Compliments medical treatment',
        'Boosts body\'s self-healing abilities',
    ],
}